<template>
<div class="mt-2" v-loading="loadingData">
    <el-row :gutter="20" class="p-3 pt0">
        <el-col :span="4">
            <div class="img_pat">
                <img :src="url + (model.patientImage ? model.patientImage.image : '' )" alt />
            </div>
        </el-col>
        <!-- end el-col -->
        <el-col :span="20">
            <el-row :gutter="30">
                <el-col :span="24" class="text-right">
                    <el-button v-if="lastHistory.is_ended != 0" icon="el-icon-edit" @click="edit()" disabled type="primary" round>{{ $t('message.update') }}</el-button>
                    <el-button v-else icon="el-icon-edit" @click="edit()" type="primary" round>{{ $t('message.update') }}</el-button>
                </el-col>
                <el-col :span="8">
                    <div class="userPasten_info">
                        <div class="title_pp">{{ $t('message.surname') }}:</div>
                        <div class="name_h">{{ model.surname }}</div>
                    </div>
                </el-col>
                <!-- end el-col -->
                <el-col :span="8">
                    <div class="userPasten_info">
                        <div class="title_pp">{{ $t('message.first_name') }}:</div>
                        <div class="name_h">{{ model.first_name }}</div>
                    </div>
                </el-col>
                <!-- end el-col -->
                <el-col :span="8">
                    <div class="userPasten_info">
                        <div class="title_pp">{{ $t('message.patronymic') }}:</div>
                        <div class="name_h">{{ model ? model.patronymic : '' }}</div>
                    </div>
                </el-col>
                <!-- end el-col -->
                <el-col :span="8">
                    <div class="userPasten_info">
                        <div class="title_pp">{{ $t('message.code_id') }}:</div>
                        <div class="name_h">{{ model.code }}</div>
                    </div>
                </el-col>
                <!-- end el-col -->
                <el-col :span="8">
                    <div class="userPasten_info">
                        <div class="title_pp">{{ $t('message.phone') }}:</div>
                        <div class="name_h">{{ model.phone | formatPhoneNumber }}</div>
                    </div>
                </el-col>
                <!-- end el-col -->
                <el-col :span="8">
                    <div class="userPasten_info">
                        <div class="title_pp">{{ $t('message.dob') }}</div>
                        <div class="name_h">{{ model.born_date }}</div>
                    </div>
                </el-col>
                <!-- end el-col -->
                <el-col :span="8">
                    <div class="userPasten_info">
                        <div class="title_pp">{{  $t('message.gender')  }}:</div>
                        <div class="name_h">
                            <template v-if="model.gender === 'man'">{{ $t('message.male') }}</template>
                            <template v-else>{{ $t('message.female') }}</template>
                        </div>
                    </div>
                </el-col>
                <!-- end el-col -->
                <el-col :span="8">
                    <div class="userPasten_info">
                        <div class="title_pp">{{ $t('message.citizenship') }}:</div>
                        <div class="name_h">{{ model.citizenship? model.citizenship.name : '' }}</div>
                    </div>
                </el-col>
                <!-- end el-col -->
                <el-col :span="8">
                    <div class="userPasten_info">
                        <div class="title_pp">{{ $t('message.nationality') }}:</div>
                        <div class="name_h">{{ model.nationality? model.nationality.name : '' }}</div>
                    </div>
                </el-col>
                <!-- end el-col -->
                <el-col :span="8">
                    <div class="userPasten_info">
                        <div class="title_pp">{{ $t('message.born_place') }}:</div>
                        <div class="name_h">{{ model.born_place }}</div>
                    </div>
                </el-col>
                <!-- end el-col -->
                <el-col :span="8">
                    <div class="userPasten_info">
                        <div class="title_pp">{{ $t('message.passport_number') }}:</div>
                        <div class="name_h">{{ model.passport_number }}</div>
                    </div>
                </el-col>
                <!-- end el-col -->
                <el-col :span="8">
                    <div class="userPasten_info">
                        <div class="title_pp">{{ $t('message.date') }}:</div>
                        <div class="name_h">{{ model.given_date }}</div>
                    </div>
                </el-col>
                <!-- end el-col -->
            </el-row>
        </el-col>
        <!-- end el-col -->
    </el-row>

    <el-tabs type="border-card" class="mt-5">
        <el-tab-pane label="Адрес регистрации">
            <el-row :gutter="30">
                <el-col :span="8">
                    <div class="userPasten_info">
                        <div class="title_pp">{{ $t('message.country') }}:</div>
                        <div class="name_h">{{ model.state? model.state.name: '' }}</div>
                    </div>
                </el-col>
                <!-- end el-col -->
                <el-col :span="8">
                    <div class="userPasten_info">
                        <div class="title_pp">{{ $t('message.city') }}:</div>
                        <div class="name_h">{{ model.city ? model.city.name : '' }}</div>
                    </div>
                </el-col>
                <!-- end el-col -->

                <el-col :span="8">
                    <div class="userPasten_info">
                        <div class="title_pp">{{ $t('message.region') }}:</div>
                        <div class="name_h">{{ model.region?  model.region.name : '' }}</div>
                    </div>
                </el-col>
                <!-- end el-col -->
                <el-col :span="8">
                    <div class="userPasten_info">
                        <div class="title_pp">{{ $t('message.street') }}:</div>
                        <div class="name_h">{{ model.street }}</div>
                    </div>
                </el-col>
                <!-- end el-col -->
                <el-col :span="8">
                    <div class="userPasten_info">
                        <div class="title_pp">{{ $t('message.district') }}:</div>
                        <div class="name_h">{{ model.district }}</div>
                    </div>
                </el-col>
                <!-- end el-col -->

                <el-col :span="8">
                    <div class="userPasten_info">
                        <div class="title_pp">{{ $t('message.house') }}:</div>
                        <div class="name_h">{{ model.home }}</div>
                    </div>
                </el-col>
                <!-- end el-col -->
                <el-col :span="8">
                    <div class="userPasten_info">
                        <div class="title_pp">{{ $t('message.flat') }}:</div>
                        <div class="name_h">{{ model.flat }}</div>
                    </div>
                </el-col>
                <!-- end el-col -->
            </el-row>
        </el-tab-pane>
        <el-tab-pane :label="$t('message.add_document')">
            <el-row :gutter="30">
                <el-col :span="4">
                    <div class="file_user">
                        <a :href="url + (model.patientFile? model.patientFile.file : '')">
                            <i class="el-icon-folder-checked"></i>
                        </a>
                    </div>
                </el-col>
                <!-- end el-col -->
            </el-row>
        </el-tab-pane>
        <el-tab-pane :label="$t('message.documents')">
            <el-row :gutter="30">
                <el-col :span="8">
                    <div class="userPasten_info">
                        <div class="title_pp">{{ $t('message.type_documents') }}:</div>
                        <div class="name_h">{{ model.documentType ? model.documentType.name : '' }}</div>
                    </div>
                </el-col>
                <!-- end el-col -->

                <el-col :span="8">
                    <div class="userPasten_info">
                        <div class="title_pp">{{ $t('message.series_and_number') }}:</div>
                        <div class="name_h">{{ model.series_and_number }}</div>
                    </div>
                </el-col>
                <!-- end el-col -->

                <el-col :span="8">
                    <div class="userPasten_info">
                        <div class="title_pp">{{ $t('message.authority') }}:</div>
                        <div class="name_h">{{ model.authority }}</div>
                    </div>
                </el-col>
                <!-- end el-col -->

                <el-col :span="8">
                    <div class="userPasten_info">
                        <div class="title_pp">{{ $t('message.date_of_issue') }}:</div>
                        <div class="name_h">{{ model.date_of_issue }}</div>
                    </div>
                </el-col>
                <!-- end el-col -->
            </el-row>
            <!-- end el-row -->
        </el-tab-pane>
        <el-tab-pane :label="$t('message.organization')">
            <el-row :gutter="30">
                <el-col :span="8">
                    <div class="userPasten_info">
                        <div class="title_pp">{{ $t('message.organization') }}:</div>
                        <div class="name_h">{{ model.organization? model.organization.company_name: '' }}</div>
                    </div>
                </el-col>
                <!-- end el-col -->
            </el-row>
        </el-tab-pane>
    </el-tabs>

    <el-drawer size="100%"  :wrapperClosable="false"
        :visible.sync="drawer.update.status" 
        :ref="drawer.update.name" 
        @opened="drawerOpened(drawer.update.component)" 
        @closed="drawerClosed(drawer.update.component)">
        <crm-update :selected="selectedModel" :ref="drawer.update.component" :drawer-name="drawer.update.name"></crm-update>
    </el-drawer>
</div>
</template>

<script>
import CrmUpdate from "./components/crmUpdate";

import {
    mapActions,
    mapGetters
} from 'vuex';
import list from "@/utils/mixins/list";
import drawer from "@/utils/mixins/drawer";
import {formatPhoneNumber} from "@/filters";
export default {
    mixins: [list, drawer],
    props: ['selected'],
    name: "patientHome",
    components: {
        CrmUpdate
    },
    data() {
        return {
            url: process.env.VUE_APP_URL_DOCS,
            loadingData: false,
            selectedModel: {},
            drawer: {
                update: {
                    name: "update",
                    status: false,
                    component: 'componentDrawerUpdate'
                }
            },
            model: {}
        }
    },
    computed: {
        getId() {
            return this.$route.params.id;
        },
        ...mapGetters({
            lastHistory: 'dailyTreatments/lastHistory',
            list: 'hospitalPatients/list',
            columns: "hospitalPatients/columns",
            pagination: "hospitalPatients/pagination",
            filter: "hospitalPatients/filter",
            sort: "hospitalPatients/sort",
        }),
        actions: function () {
            return ['edit', 'delete'];
        }
    },
    mounted() {
        // this.getLastHistory({id: this.$route.params.id}) ;           
        this.loadingData = true;
        this.editModel(this.getId)
            .then(res => {
                this.loadingData = false;
                this.model = res.data.hospitalPatient;
            }).catch(err => {
                this.loadingData = false;
                this.$notify({
            title: "Ошибка",
            type: "error",
            offset: 130,
            message: err.error.message,
          })
            });
    },
    beforeRouteLeave(to, from, next) {
        this.empty();
        this.emptyHistory();
        next();
    },
    methods: {
      formatPhoneNumber,
        ...mapActions({
            updateList: 'hospitalPatients/index',
            updateSort: "hospitalPatients/updateSort",
            updateFilter: "hospitalPatients/updateFilter",
            updateColumn: "hospitalPatients/updateColumn",
            updatePagination: "hospitalPatients/updatePagination",
            editModel: 'hospitalPatients/show',
            empty: 'hospitalPatients/empty',
            emptyHistory: 'patientHistories/empty',
            delete: 'hospitalPatients/destroy',
            refreshData: 'hospitalPatients/refreshData',
        }),
        fetchData() {
            this.loadingData = true;
            this.editModel(this.getId)
                .then(res => {
                    this.loadingData = false;
                    this.model = res.data.hospitalPatient;
                }).catch(err => {
                    this.loadingData = false;
                    this.$notify({
            title: "Ошибка",
            type: "error",
            offset: 130,
            message: err.error.message,
          })
                });
        },

        refresh() {
            this.refreshData()
                .then(res => {
                    this.filterForm = JSON.parse(JSON.stringify(this.filter));
                })
                .catch(err => {

                })
        },
        edit() {
            this.selectedModel = this.model;
            this.drawer.update.status = true;
        },

        destroy(model) {
            this.delete(model.id)
                .then(res => {
                    this.$notify({
            title: 'Успешно',
            type: "success",
            offset: 130,
            message: res.message
          });
                    this.fetchData()
                })
                .catch(err => {
                    console.log(err)
                })
        },
        emptyModel() {
            this.empty()
        },
    }
};
</script>

<style lang="scss">
.img_pat {

    // text-align: center;
    img {
        width: 100%;
        height: auto;
        border-radius: 7px;
    }
}

.title_pp {
    color: #717171;
    margin-right: 20px;
    // min-width: 150px;
}

.name_h {
    font-weight: 600;
}

.file_user {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #e2e2e2;
    border-radius: 10px;
    margin-bottom: 30px;

    a {
        text-decoration: none;
        font-size: 14px;
    }

    a:hover {
        text-decoration: none;
    }

    i {
        color: #d8d8d8;
        font-size: 10vw;
    }
}
</style>
